import api from "../configs/api";

export default class ProductService {

	async findAll(size, page, name) {
		if(name) {
			return api.get(`/admin/product?size=${size}&page=${page}&name=${name}`);
		}
		return api.get(`/admin/product?size=${size}&page=${page}`);
	}

    async findById(id) {
		return api.get( `/admin/product/` + id);
	}

	async save(product) {
		return api.post( `/admin/product`, product);
    }

	async saveAll(products) {
		return api.post( `/admin/product/all`, products);
	}

	async delete(id) {
		return api.delete( `/admin/product/` + id);
	}
}
