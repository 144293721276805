<template>

  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Procedimento</div>
    <div class="text-500 mb-5">Insira os procedimentos realizados pela clínica</div>
    <div class="surface-card p-4 shadow-2 border-round p-fluid mb-3">
      <div class="field">
        <label for="name" :class="{'p-error': v$.procedure.name.$error}">Nome do Procedimento *</label>
        <div class="p-inputgroup">
          <InputText id="name" @blur="v$.procedure.name.$touch"
                     v-model.trim="procedure.name" required="true"
                     maxlength="50"
                     autofocus :class="{'p-invalid': v$.procedure.name.$error}" />
        </div>
        <small class="p-error" v-for="error of v$.procedure.name.$errors"
               :key="error.$uid">{{error.$message}}</small>
      </div>
      <div class="field mb-4 col-4">
        <label for="categoria" :class="{'p-error': v$.procedure.price.$error}">Valor do Procedimento *</label>
        <div class="p-inputgroup">
          <InputNumber v-model="procedure.price" mode="currency"
                       @blur="v$.procedure.price.$touch"
                       aria-required="true"
                       :min="0"
                       :max="100000"
                       autofocus :class="{'p-invalid': v$.procedure.price.$error}"
                       locale="pt-BR" currency="BRL"
                       :minFractionDigits="2"
                       :max-fraction-digits="2"
                       showButtons :step="50.00"
          />
        </div>
        <small class="p-error" v-for="error of v$.procedure.price.$errors"
               :key="error.$uid">{{error.$message}}</small>
      </div>
      <div class="field">
        <label for="phone">Descrição</label>
        <div class="p-inputgroup">
          <Textarea maxlength="255" v-model="procedure.description" autofocus rows="5"/>
        </div>
      </div>

    </div>
    <div class="card shadow-2 border-round mb-3">
      <span class="text-1xl" >Produto(s) Utilizados</span>
      <divider class="pb-3"></divider>
      <div class="field">
        <PickList v-model="products" dataKey="id" v-model:selection="procedure.products">
          <template #sourceheader>
            Produtos
          </template>
          <template #targetheader>
            Produtos Selecionados
          </template>
          <template #item="slotProps">
            <div class="product-item">
              <div class="product-list-detail">
                <h6 class="mb-2">{{slotProps.item.name}}</h6>
              </div>
            </div>
          </template>
        </PickList>
      </div>
    </div>

    <Toolbar class="mb-3">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Cancelar" icon="pi pi-times" class="p-button-outlined mr-2" @click.prevent="$router.push({name: 'procedimentos'})"/>
          <Button label="Salvar" icon="pi pi-check" class="p-button-success" @click.prevent="save" />
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import ProcedureService from "@/service/ProcedureService";
import ProductService from "@/service/ProductService";
import differenceBy from 'lodash/differenceBy';

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "produto-create",
  created() {
    this.procedureService = new ProcedureService();
    this.productService = new ProductService();
  },
  data() {
    return {
      procedure: {},
      procedureId: null,
      products: null,
      procedureService: null,
      productService: null,
      loader: null
    }
  },
  validations() {
    return {
      procedure: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        price: {
          required: helpers.withMessage('Campo Valor é obrigatório.', required)
        }
      }
    }
  },
  async mounted() {
    this.v$.$reset();
    this.procedureId = this.$route.query.id;

    this.loader = this.$loading.show();
    if(this.procedureId) {
      await this.findById();
    }
    await this.findProducts();
    this.loader.hide();
  },
  methods: {
    async save() {
      const isFormValid = await this.v$.$validate();
      if(!isFormValid) {
        return;
      }
      this.loader = this.$loading.show();
      try {
        this.procedure.products = this.products[1];
        const response = await this.procedureService.save(this.procedure);
        if(response.status === 200) {
          this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Procedimento cadastrado com sucesso.', life: 3000});
          if(!this.procedureId) {
            this.procedure = {};
            await this.findProducts();
            this.v$.$reset();
          }
        }
        this.loader.hide();
      } catch(e) {
        this.loader.hide();
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    },
    async findById() {
      const response = await this.procedureService.findById(this.procedureId);
      if(response.status === 200) {
        this.procedure = response.data;
      }
    },
    async findProducts() {
      const response = await this.productService.findAll();
      if(response.status === 200) {
        if(this.procedureId) {
          const results = differenceBy(response.data.content, this.procedure.products, 'id');
          this.products = [results, this.procedure.products];
        } else {
          this.products = [response.data.content, []];
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
