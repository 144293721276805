import api from '../configs/api';

export default class ProcedureService {

	async findAll(size, page, name) {
		if(name) {
			return api.get(`/admin/procedure?size=${size}&page=${page}&name=${name}`);
		}
		return api.get(`/admin/procedure?size=${size}&page=${page}`);
	}

    async findById(id) {
		return api.get(`/admin/procedure/` + id);
	}

	async save(procedure) {
		return api.post(`/admin/procedure`, procedure);
    }

	async delete(id) {
		return api.delete(`/admin/procedure/` + id);
	}
}
